import { getCookie } from '@vcc-package/storage/cookies';
import { SiteSlug, isValidSiteSlug } from '@volvo-cars/market-sites';
import * as ld from 'launchdarkly-js-client-sdk';

/**
 * Generates a LaunchDarkly context object for a given client and site.
 *
 * @param clientName - The name of the client. Typically the consuming App. This is a custom attribute used for analytics.
 * @param siteSlug - The slug of the site. undefined if not matched by useCurrentMarketSite().
 * @param stringifiedAdditionalAttributes - A JSON string representing additional attributes to include in the context.
 * @returns A LaunchDarkly single kind context object or null if the site slug is invalid.
 */
export function getContext(
  clientName: string,
  siteSlug: SiteSlug | undefined,
  stringifiedAdditionalAttributes: string,
): ld.LDSingleKindContext | null {
  if (!isValidSiteSlug(siteSlug)) {
    console.warn('getContext(): Invalid site slug.');
    return null;
  }

  /**
   * For anonymouse users, a context key is provided by LD if no key is provided.
   * This key (random UUID) is stored in local storage and re-used between page
   * views. If a visitor has concented to cookies, the GA cookie is used as key.
   *
   * There is an ongoing discussion and exploration around what we use to track
   * visitors on dotcom while still complying with cookie and GDPR legislation.
   *
   * Read more on: https://www.notion.so/volvocars/Launch-Darkly-user-context-150c3ef07ab7808cba67c7bbb749eccf
   */
  const key = getGAUserID();

  const additionalAttributes = JSON.parse(stringifiedAdditionalAttributes);

  const context = {
    kind: 'user',
    key,
    anonymous: true,
    ssr: false,
    clientName,
    siteSlug,
    ...additionalAttributes,
  };

  return context;
}

function getGAUserID() {
  const gaCookie = getCookie('_ga');
  return gaCookie ? gaCookie.replace(/^GA1\.[0-9]\./, '') : undefined;
}
