'use client';
import { ROOT_ID } from '@volvo-cars/site-nav-embed/src/constants';
import React from 'react';
import { Persistor, PersistorProps } from './Persistor';

export const SiteNavigationEmbedPersistor: React.FC<
  {
    children: React.ReactNode;
  } & Pick<PersistorProps, 'strategy'>
> = ({ children, strategy }) => {
  return (
    <Persistor
      selector="#site-nav-embed"
      getShadowRoot={() => {
        return document.getElementById(ROOT_ID)?.shadowRoot;
      }}
      cleanUp={() => {
        window.siteNavigationController?.reset?.();
        //@ts-ignore
        window.siteNavigationController?.callEntries?.();
      }}
      strategy={strategy}
    >
      {children}
    </Persistor>
  );
};
