import NextLink, { type LinkProps } from 'next/link';
import type React from 'react';

export const LinkComponent: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  href,
  ...linkProps
}) => {
  // we only want to use next/link for internal links
  // external links are better served with an basic a tag
  if (typeof href === 'object' || href?.indexOf('/') === 0) {
    return (
      <NextLink href={href} legacyBehavior>
        <a {...linkProps}>{children}</a>
      </NextLink>
    );
  }

  return (
    <a href={href} {...linkProps}>
      {children}
    </a>
  );
};
