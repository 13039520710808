import { customAlphabet } from 'nanoid';
import { useState } from 'react';
import { StyleRenderer } from 'vcc-ui';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 5);

let cachedRenderer: StyleRenderer | undefined;

/**
 *
 * @param getStyleRenderer Function that returns a StyleRenderer
 * @returns a new StyleRenderer when needed
 */
export const useStyleRenderer = (getStyleRendererBase: () => StyleRenderer) => {
  function getStyleRenderer() {
    const styleRenderer = getStyleRendererBase();
    // Make sure client-only classnames generated while document is still loading
    // don't clash with server-generated styles that might load later,
    // as fela's classname uniqueness gruarantee relies on cached styles from the server
    // We need the newly client-generated classnames to be as unique as possible
    // To prevent potential clashes with later flushed server-side classnames
    if (typeof window !== 'undefined') {
      (styleRenderer as any).generateClassName = () => nanoid();
    }
    return styleRenderer;
  }

  const [initialRenderer] = useState(() => {
    return getStyleRenderer();
  });

  if (typeof window === 'undefined') {
    return initialRenderer;
  }

  /*   Create a new style renderer to re-read fela server-generated stylesheets after the first 
  render pass as more stylesheets could have been loaded with streaming before document is fully loaded.

   This is to prevent classname clashes between server and client while hydrating.
   By default, Fela only ever reads server-generated stylesheets on the first render
   of StyleProvider as long as the renderer didn't change. When streaming with suspense, styles will
   be flushed gradually using `useServerInsertedHTML`. This means that more stylesheets could
   be loaded after fela has already read the server stylesheets on the first render.
   
   Exact steps:
  1. Stylesheets are flushed to the browser gradually using `useServerInsertedHTML`, 
     this hook is called as many times as needed depending on the number of suspense boundaries
  2. While the document is still loading, React starts hydrating on the client and thus Fela
  3. Fela reads the server-generated stylesheets only on the first render (one time) and adds them to the cache
  4. While Fela is hydrating, if a css rule already exists in the server-generated stylesheets, 
     Fela will just re-use the classname of that rule, otherwise it will generate a new classname and rule and 
     add it to a client-generated stylesheet(in memory)
  5. The client-only classname is now generated in a client-only stylesheet, and a similar class name
     with a different css rule is streamed later in a server-generated stylesheet
  6. We now have two classname definitions with different css rules in the document, one from the
     client and one from the server
  7. Bad UI can be observed as the classnames will override each other
     
  We fix this issue by forcing Fela to re-cache the server stylesheets 
  after each re-render by giving it a new renderer as long as the document
  hasn't finished loading 
   */
  if (document.readyState !== 'complete') {
    cachedRenderer = getStyleRenderer();
    return cachedRenderer;
  }

  return cachedRenderer || initialRenderer;
};
