import { isValidSiteSlug } from '@volvo-cars/site-nav-embed/src/fetchSiteNavigationProps';
import * as ld from 'launchdarkly-js-client-sdk';

/**
 * Initializes the LaunchDarkly client if it hasn't been initialized already.
 *
 * @param {Object} params - The parameters for initializing the client.
 * @param {string} [params.clientId] - The Client Side ID for LaunchDarkly project.
 * @param {ld.LDSingleKindContext | null} params.context - The LaunchDarkly context to be used in the client.
 * @param {string} [params.siteSlug] - The site slug for the current market.
 */
export async function initializeClient({
  clientId,
  context,
  siteSlug,
}: {
  clientId: string;
  context: ld.LDSingleKindContext | null;
  siteSlug?: string;
}): Promise<ld.LDClient | null> {
  if (!isValidSiteSlug(siteSlug)) {
    console.warn('initializeClient(): Invalid site slug.');
    return null;
  }

  if (!context) {
    console.warn('initializeClient(): LaunchDarkly context is missing.');
    return null;
  }

  /**
   * On SDK client initialization we configure the client to send events with
   * evaluationReason back to Launch Darkly every time a flag is evaluated. The
   * context is also sent. This is where we might end up on murky waters, since
   * the data sent here i exported from Launch Darkly into Snowflake and then
   * into Eppo.
   *
   * Read more on: https://www.notion.so/volvocars/Launch-Darkly-user-context-150c3ef07ab7808cba67c7bbb749eccf
   */
  const LDClient = ld.initialize(clientId, context, {
    evaluationReasons: true,
    sendEvents: true,
    sendEventsOnlyForVariation: true,
  });

  try {
    await LDClient.waitForInitialization(5);
    return LDClient;
  } catch (error) {
    return null;
  }
}
