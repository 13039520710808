'use client';
import React from 'react';
import { Persistor, PersistorProps } from './Persistor';

export const SiteFooterEmbedPersistor: React.FC<
  {
    children: React.ReactNode;
  } & Pick<PersistorProps, 'strategy'>
> = ({ children, strategy }) => {
  return (
    <Persistor
      selector="#site-footer-embed"
      getShadowRoot={() => {
        return document.getElementById('vcc-site-footer')?.shadowRoot;
      }}
      cleanUp={() => {
        //@ts-ignore
        window.siteFooterController?.reset?.();
        //@ts-ignore
        window.siteFooterController?.callEntries?.();
      }}
      strategy={strategy}
    >
      {children}
    </Persistor>
  );
};
