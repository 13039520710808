// TODO: Fix eslint issues the next time this file is edited.

'use client';
import { FeatureFlagsProvider } from '@vcc-package/launch-darkly-utils/EXPERIMENTAL/js-sdk-client';
import {
  getVariablesFromPublicConfig,
  registerBrowserInstrumentation,
} from '@vcc-package/opentelemetry/browser';
import {
  Translator,
  useSharedTranslationsFlusher,
} from '@vcc-package/react-shared-translations';
import { createRenderer } from '@vcc-www/global-style-provider';
import { BreakpointsProvider } from '@vcc-www/hooks';
import { useCallOnce } from '@vcc-www/hooks/useCallOnce';
import { useFlushStyles } from '@vcc-www/hooks/vcc-ui/useFlushStyles';
import { useStyleRenderer } from '@vcc-www/hooks/vcc-ui/useStyleRenderer';
import { VolvoCarsUrlProvider, urlFromString } from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import { TranslatorProvider } from '@volvo-cars/react-shared-translations';
import {
  usePathname,
  useSearchParams,
  useServerInsertedHTML,
} from 'next/navigation';
import type React from 'react';
import { useEffect, useState } from 'react';
import { LinkComponent } from 'src/components/LinkComponent';
import { PublicEnv, getPublicEnv } from 'src/public-env';
import { ConfigProvider, StyleProvider, ThemePicker } from 'vcc-ui';

const getStyleRenderer = () => {
  return createRenderer(undefined, '#__next');
};

registerBrowserInstrumentation({
  ...getVariablesFromPublicConfig(getPublicEnv()),
});

// RootLayoutProvider that is shared across all routes
export const RootLayoutProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [routeChangeHandler, setRouteChangeHandler] = useState<() => void>();

  // Update VolvoCarsUrl when the route changes. VolvoCarsUrl by default listens
  // to click events only which is too early some times

  // biome-ignore lint/correctness/useExhaustiveDependencies: Reaction to route change is intentional
  useEffect(() => {
    if (!routeChangeHandler) return;
    routeChangeHandler();
  }, [pathname, searchParams, routeChangeHandler]);

  const url = urlFromString(pathname || '');
  const { locale, languageDirection } = getMarketSite(url.siteSlug);

  const styleRenderer = useStyleRenderer(getStyleRenderer);
  const flushStyles = useFlushStyles(getStyleRenderer);
  const flushTranslations = useSharedTranslationsFlusher();

  const [translator] = useState(() => {
    return new Translator();
  });

  const renderPublicEnv = useCallOnce(() => <PublicEnv />);

  useServerInsertedHTML(() => {
    return (
      <>
        {flushStyles(styleRenderer)}
        {flushTranslations(translator)}
        {renderPublicEnv()}
      </>
    );
  });

  const { ldGlobalClientId, ldProjectClientId } = getPublicEnv();

  return (
    <BreakpointsProvider>
      <ConfigProvider config={{ locale, linkComponent: LinkComponent }}>
        <StyleProvider renderer={styleRenderer}>
          <ThemePicker direction={languageDirection}>
            <VolvoCarsUrlProvider
              url={urlFromString(pathname || '')}
              setRouteChangeHandler={setRouteChangeHandler}
            >
              <TranslatorProvider locale={locale} translator={translator}>
                <FeatureFlagsProvider
                  clientName="cbv-learn"
                  ldGlobalClientSideId={ldGlobalClientId || ''}
                  ldProjectClientSideId={ldProjectClientId || ''}
                >
                  {children}
                </FeatureFlagsProvider>
              </TranslatorProvider>
            </VolvoCarsUrlProvider>
          </ThemePicker>
        </StyleProvider>
      </ConfigProvider>
    </BreakpointsProvider>
  );
};
