import * as ld from 'launchdarkly-js-client-sdk';
import type { ReadonlyURLSearchParams } from 'next/navigation';
import { getFeatureFlagFromQuery } from './queryParams';

/**
 * Retrieves the value of a feature flag.
 *
 * @template T - The type of the flag value.
 * @param {string} flag - The key of the feature flag.
 * @param {T} defaultValue - The default value to use if the flag is not set or the client is not initialized.
 * @param {ReadonlyURLSearchParams} queryParams - The URL search parameters to check for the flag value. Flag key/value pairs can be added as a comma separated list under the featureFlags parameter.
 * @param {ld.LDClient | null} client - The LaunchDarkly client instance.
 * @returns {T} - The value of the feature flag, or the default value if the flag is not set or the client is not initialized.
 */
export function getFlagValue<T>(
  flag: string,
  defaultValue: T,
  queryParams: ReadonlyURLSearchParams | null,
  client: ld.LDClient | null,
): T {
  const allowFlagsFromQuery =
    typeof window !== 'undefined' &&
    window.origin !== 'https://www.volvocars.com';

  if (queryParams && allowFlagsFromQuery) {
    const featureFlagFromQuery = getFeatureFlagFromQuery(flag, queryParams);

    if (featureFlagFromQuery) {
      return featureFlagFromQuery as T;
    }
  }

  if (!client) return defaultValue;

  const details = client.variationDetail(flag, defaultValue);

  /*
   * We only return the actual flag value. variationDetail() is used to receive richer
   * data, like flag evaluation reason. This is included in the event sent to LaunchDarkly
   * and eventually used for analytics purposes.
   */
  return details.value;
}
