import { getElasticVariablesFromEnv } from '@vcc-package/opentelemetry';
import { createPublicEnv } from '@vcc-www/public-env';

export const { PublicEnv, getPublicEnv } = createPublicEnv({
  ldProjectClientId: process.env.LAUNCH_DARKLY_CLIENT_ID,
  ldGlobalClientId: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID_GLOBAL,
  buildSourceVersion: process.env.BUILD_SOURCEVERSION,
  hostname: process.env.HOSTNAME,
  publicHost: process.env.NEXT_PUBLIC_HOST,
  deployEnv: process.env.DEPLOY_ENV,
  ...getElasticVariablesFromEnv(),
});
