import { getSiteNavigationUrl } from './getSiteNavigationUrl';
import { SiteNavigationProps } from './types';

export class SiteNavError extends Error {
  name = 'SiteNavError';
  status: number;
  url: string;

  constructor(message: string, resp: { url: string; status: number }) {
    super(`${message} (${resp.status} @ ${resp.url})`);
    this.status = resp.status;
    this.url = resp.url;
    Object.setPrototypeOf(this, SiteNavError.prototype);
  }
}

export type FetchSiteNavigationPropsOptions = {
  baseUrl?: string;
  variant?: SiteNavigationProps['variant'];
};

/**
 * Fetch the properties to pass to the SiteNavigation component.
 *
 * @param siteSlugOrPathname Site slug ('us', 'fr-ca', 'intl') or full path being rendered, including market site slug.
 */
export async function fetchSiteNavigationProps(
  siteSlugOrPathname?: string,
  {
    variant,
    baseUrl = process.env.SITE_NAV_URL,
  }: FetchSiteNavigationPropsOptions = {},
): Promise<SiteNavigationProps> {
  const siteSlug = getSiteSlug(siteSlugOrPathname);
  const { src, alt } = getSiteNavigationUrl({
    siteSlug,
    baseUrl,
    variant,
  });
  if (typeof fetch === 'undefined') {
    throw new Error('Please include a fetch polyfill');
  }

  let headers;
  const authHeaderName = process.env.SITE_NAV_AUTH_HEADER_NAME;
  const authHeaderValue = process.env.SITE_NAV_AUTH_HEADER_VALUE;
  if (authHeaderName && authHeaderValue) {
    headers = {
      [authHeaderName]: authHeaderValue,
    };
  }

  const resp = await fetch(src || alt, {
    headers,
    //@ts-ignore
    next: {
      // Revalidate every 5 minutes
      revalidate: 60 * 5,
    },
  });

  if (!resp.ok) {
    throw new SiteNavError(`Failed to fetch Site Nav`, resp);
  }

  return {
    esi: false,
    baseUrl,
    variant,
    html: await resp.text(),
    siteSlug,
  };
}

// Starts and ends with a letter, with optional letters or - in between
const siteSlugRegex = /^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/;

export function getSiteSlug(siteSlugOrPathname?: string) {
  let siteSlug = siteSlugOrPathname;
  if (siteSlug && siteSlug.startsWith('/')) {
    const urlParts = siteSlug.split('/');
    if (urlParts.length > 1) {
      siteSlug = urlParts[1];
    }
  }
  if (process.env.NODE_ENV === 'development' && !isValidSiteSlug(siteSlug)) {
    console.warn(`Invalid site slug: ${siteSlug}`);
  }
  return siteSlug?.toLowerCase();
}

export function isValidSiteSlug(siteSlug?: string) {
  return !!siteSlug && siteSlugRegex.test(siteSlug);
}
