import type { LDFlagSet } from 'launchdarkly-js-client-sdk';
import type { ReadonlyURLSearchParams } from 'next/navigation';

/**
 * Retrieves the value of a specific feature flag from the query parameters.
 *
 * @param {string} flag - The name of the feature flag to retrieve.
 * @param {ReadonlyURLSearchParams} searchParams - The ReadonlyURLSearchParams object from NextJS, containing the query parameters.
 * @returns The value of the specified feature flag, or `undefined` if the flag is not present.
 *
 * @example
 * ```ts
 * // URL: https://localhost:3000/master?featureFlags=flag1=true,flag2=demo
 * const searchParams = useSearchParams();
 * const flagValue = getFeatureFlagFromQuery('flag1', searchParams);
 * console.log(flagValue); // Output: 'true'
 * ```
 */
export const getFeatureFlagFromQuery = (
  flag: string,
  searchParams: ReadonlyURLSearchParams,
): unknown => {
  const flags = getFeatureFlagsFromQuery(searchParams);
  return flags[flag];
};

export const getFeatureFlagsFromQuery = (
  searchParams: ReadonlyURLSearchParams,
): LDFlagSet => {
  const query = searchParams?.get('featureFlags');

  if (!query) return {};

  const featureFlagsQuery = query.split(',');
  const featureFlags: LDFlagSet = {};

  featureFlagsQuery.forEach((flag) => {
    const flagString: string[] = flag.split('=');
    const property: string = flagString[0];
    let value: any;
    switch (flagString[1]) {
      case 'false':
        value = false;
        break;
      case 'true':
        value = true;
        break;
      default:
        value = flagString[1];
    }
    featureFlags[property] = value;
  });

  return featureFlags;
};
