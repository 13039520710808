export type Style = (
  | {
      type: 'stylesheet';
    }
  | {
      type: 'style';
      content: string | null;
    }
) & {
  attributes: Record<string, string>;
};

type StyleCallback = (style: Style) => void;

function isHTMLLinkElement(node: Node): node is HTMLLinkElement {
  return node instanceof HTMLLinkElement;
}

function isHTMLStyleElement(node: Node): node is HTMLStyleElement {
  return node instanceof HTMLStyleElement;
}

function getAttributes(style: HTMLStyleElement) {
  const attributes: Record<string, string> = {};
  for (let i = 0; i < style.attributes.length; i++) {
    const attr = style.attributes[i];
    attributes[attr.name] = attr.value;
  }
  return attributes;
}

/**
 * Vite runtime does not re-append a stylesheet if it has already been appended
 * once.
 *
 * This is a problem because our initially cloned embeds will not include later
 * dynamically loaded stylesheets. This observes the document for new
 * stylesheets and re-appends them when the cloned embed is added to the
 * document.
 */
export function createStyleSheetObserver(
  callback: StyleCallback,
  targetNode: Document | ShadowRoot,
) {
  function processStyleSheets(addedNode: Node) {
    if (!(addedNode instanceof HTMLElement)) {
      return;
    }

    const isAlreadyAdded =
      addedNode.getAttribute('data-embed-persistor') === 'true';

    if (isAlreadyAdded) {
      return;
    }

    if (
      isHTMLLinkElement(addedNode) &&
      addedNode.rel.toLowerCase() === 'stylesheet'
    ) {
      callback({
        type: 'stylesheet',
        attributes: getAttributes(addedNode),
      });

      return;
    }

    if (isHTMLStyleElement(addedNode)) {
      callback({
        type: 'style',
        attributes: getAttributes(addedNode),
        content: addedNode.textContent,
      });

      return;
    }

    const stylesheetLinks = addedNode.querySelectorAll<HTMLLinkElement>(
      'link[rel="stylesheet"]',
    );

    for (const stylesheet of stylesheetLinks) {
      callback({
        type: 'stylesheet',
        attributes: getAttributes(stylesheet),
      });
    }

    const styleElements = addedNode.querySelectorAll<HTMLStyleElement>('style');

    for (const style of styleElements) {
      callback({
        type: 'style',
        content: style.textContent,
        attributes: getAttributes(style),
      });
    }

    return;
  }

  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        for (const addedNode of mutation.addedNodes) {
          processStyleSheets(addedNode);
        }
      }
    }
  });

  observer.observe(targetNode, { childList: true, subtree: true });

  return observer;
}
