'use client';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import * as ld from 'launchdarkly-js-client-sdk';
import _ from 'lodash';
import { useSearchParams } from 'next/navigation';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { initializeClient } from './utils/clients';
import { getContext } from './utils/context';
import { getFlagValue } from './utils/flags';

/**
 * Type definitions for the context in our launch darkly utils context.
 *
 * We include both SDK clients in the React Context. These can be used to work
 * directly with the underlying launchdarkly-js-client-sdk client. For ease of
 * use we also include custom functions (getProjectFlag and getGlobalFlag) to
 * work with flag evaluations. Lastly, we keep boolean loading state for both
 * project and global clients. This helps avoiding flag evaluations getting
 * stuck on default values if hooks are called before client is initialized.
 */
type FeatureFlagContextType = {
  getProjectFlag: <T>(flag: string, defaultValue: T) => T;
  getGlobalFlag: <T>(flag: string, defaultValue: T) => T;
  projectClient: ld.LDClient | null;
  globalClient: ld.LDClient | null;
  clientsLoading: boolean;
};

const FeatureFlagContext = createContext<FeatureFlagContextType | null>(null);

type ProviderProps = {
  clientName: string;
  ldProjectClientSideId: string;
  ldGlobalClientSideId: string;
  customContextAttributes?: Record<string, any>;
};

/**
 * Provides a context with LaunchDarkly SDK clients and utilities for evaluating flags.
 *
 * @param {object} props - The properties object.
 * @param {string} props.clientName - The name of the client. Typically the name of your app.
 * @param {string} props.ldGlobalClientSideId - The LaunchDarkly client-side ID for the Global project called "Global DotCom".
 * @param {string} props.ldProjectClientSideId - The LaunchDarkly client-side ID for your app specific project.
 * @param {object} props.customContextAttributes - Custom attributes to be included in the context.
 */
export function FeatureFlagsProvider({
  clientName,
  ldGlobalClientSideId,
  ldProjectClientSideId,
  customContextAttributes = {},
  children,
}: PropsWithChildren<ProviderProps>) {
  const { siteSlug } = useCurrentMarketSite();
  const queryParams = useSearchParams();

  const projectClient = useRef<ld.LDClient | null>(null);
  const globalClient = useRef<ld.LDClient | null>(null);
  const [clientsLoading, setClientsLoading] = useState(true);

  /**
   * Stringify the additional attributes object so it can be memoized. This way,
   * we wont re-create the LD context when it hasn't changed.
   */
  const stringifiedAdditionalAttributes = JSON.stringify(
    customContextAttributes,
  );

  /**
   * The LaunchDarkly context that will be used in the SDK client. Memoized, as this
   * typically wont change. Both clientName and siteSlug will in general stay the same
   * throughout normal usage within an application. stringifiedAdditionalAttributes
   * might change if consumers are passing custom properties like "pathname", or others
   * that change more frequently.
   */
  const context = useMemo(() => {
    return getContext(clientName, siteSlug, stringifiedAdditionalAttributes);
  }, [clientName, siteSlug, stringifiedAdditionalAttributes]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const initializeClients = async () => {
      setClientsLoading(true);

      projectClient.current = await initializeClient({
        clientId: ldProjectClientSideId,
        context,
        siteSlug,
      });
      globalClient.current = await initializeClient({
        clientId: ldGlobalClientSideId,
        context,
        siteSlug,
      });

      /**
       * We set the clients as loaded here. If client initialization fails we still
       * consider the LD client loaded. Any evaluation done using getFlagValue
       * with a failed client will use the default value provided.
       */
      setClientsLoading(false);
    };

    /**
     * Since we're working with the same context in both clients, it should be
     * enough to just check against the context in one of the clients. This might
     * feel expensive, but should be cheaper than re-initializing the clients
     * unneccessarily.
     */
    const contextChanged = !_.isEqual(
      projectClient.current?.getContext(),
      context,
    );

    /**
     * If our SDK clients are not yet initialized and saved in a ref, we do that here.
     * The refs will persist between context re-renders. If the whole application is
     * wrapped in this Provider, this typically happens on every page navigation
     * (within a single application) due to how our RootLayoutProvider is setup.
     *
     * We also re-initialize the clients if the context change. We've explored
     * usage of calling .identify() on the clients to update context, but taking
     * our infra and this current setup into account, re-initializing the clients
     * might be our best option. Open for questioning, though.
     */
    if (contextChanged || (!projectClient.current && !globalClient.current)) {
      initializeClients();
    }
  }, [context, ldProjectClientSideId, ldGlobalClientSideId, siteSlug]);

  /**
   * Wrapper functions to easily evaluate a flag using variationDetail(),
   * together with built in support for using query params to override
   * flag values in non-production environments.
   */
  function getProjectFlag<T>(flag: string, defaultValue: T): T {
    return getFlagValue(flag, defaultValue, queryParams, projectClient.current);
  }
  function getGlobalFlag<T>(flag: string, defaultValue: T): T {
    return getFlagValue(flag, defaultValue, queryParams, globalClient.current);
  }

  return (
    <FeatureFlagContext.Provider
      value={{
        getProjectFlag,
        getGlobalFlag,
        projectClient: projectClient.current,
        globalClient: globalClient.current,
        clientsLoading,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
}

const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlagContext must be used within a FeatureFlagProvider',
    );
  }
  return context;
};

export function useProjectFlag<T>(
  flag: string,
  defaultValue: T,
): { loading: boolean; flagValue: T } {
  const context = useFeatureFlagContext();
  const flagValue = context.getProjectFlag<T>(flag, defaultValue);
  return { loading: context.clientsLoading, flagValue };
}

export function useGlobalFlag<T>(
  flag: string,
  defaultValue: T,
): { loading: boolean; flagValue: T } {
  const context = useFeatureFlagContext();
  const flagValue = context.getGlobalFlag<T>(flag, defaultValue);
  return { loading: context.clientsLoading, flagValue };
}

export function useProjectClient() {
  const context = useFeatureFlagContext();
  return context.projectClient;
}

export function useGlobalClient() {
  const context = useFeatureFlagContext();
  return context.globalClient;
}

export function useClientsLoading() {
  const context = useFeatureFlagContext();
  return context.clientsLoading;
}

/**
 * Exposing a dummy context intended to be used for Storybook. Rather than trying
 * to initialize actual LaunchDarkly clients we expose dummy variants of what's
 * available in the context.
 *
 * @param {object} props - The properties object.
 * @param {object} props.mockedProjectFlags - Mocked flag values for the project specific Launch Darkly project.
 * @param {object} props.mockedGlobalFlags - Mocked flag values for the global Launch Darkly project.
 *
 * @example
 * <StorybookFeatureFlagsProvider
 *   mockedProjectFlags={{ 'new-engine-and-battery': true }}
 * >
 */
export const StorybookFeatureFlagsProvider = ({
  mockedProjectFlags = {},
  mockedGlobalFlags = {},
  children,
}: React.PropsWithChildren<{
  mockedProjectFlags?: Record<string, any>;
  mockedGlobalFlags?: Record<string, any>;
}>) => {
  const memoizedContextValue: FeatureFlagContextType = useMemo(() => {
    return {
      getProjectFlag: (flag, defaultValue) => {
        return mockedProjectFlags[flag] ?? defaultValue;
      },
      getGlobalFlag: (flag, defaultValue) => {
        return mockedGlobalFlags[flag] ?? defaultValue;
      },
      projectClient: null,
      globalClient: null,
      clientsLoading: false,
    };
  }, [mockedProjectFlags, mockedGlobalFlags]);

  return (
    <FeatureFlagContext.Provider value={memoizedContextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
